"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldUploadZoomVideo = exports.hasActiveTrial = exports.hasActiveSubscription = void 0;
function hasActiveSubscription(account) {
    return !!account.subscription && Math.round(Date.now() / 1000) < account.subscription.periodEndAt;
}
exports.hasActiveSubscription = hasActiveSubscription;
function hasActiveTrial(account) {
    if (hasActiveSubscription(account))
        return false;
    if (!account.confirmedAt)
        return false;
    // confirmedAt is set to be 7 days in the future
    const date = new Date();
    return date.valueOf() < account.confirmedAt;
}
exports.hasActiveTrial = hasActiveTrial;
function shouldUploadZoomVideo(account, env) {
    const skipCheck = !env.CHECK_SUBSCRIPTION_BEFORE_UPLOADING;
    if (skipCheck)
        return true;
    return hasActiveSubscription(account) || hasActiveTrial(account);
}
exports.shouldUploadZoomVideo = shouldUploadZoomVideo;
