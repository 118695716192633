<script lang="ts">
  import type { CreateSubscriptionResponse } from "@kiss/functions/Stripe-CreateSubscription"
  import type { Stripe } from "@stripe/stripe-js"
  import { Form, FormGroup, RadioButtonGroup, RadioButton, Button, NumberInput } from "carbon-components-svelte"

  export let submitCaption: string
  export let showTOC: boolean
  let tocAgreed: boolean

  const apiURL = process.env.KISS_SERVICES_FUNCS_URL

  let resolveStripe: (value: Stripe) => void
  const stripeLoader: Promise<Stripe> = new Promise(resolve => {
    resolveStripe = resolve
  })

  function stripeLoaded() {
    const _stripe = window.Stripe && window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY!)
    if (!_stripe) {
      throw new Error("Failed to initialize stripe-js")
    }
    resolveStripe(_stripe)
  }
  // Silence TS6133, because Svelte's TS integration doesn't seem to think it's being used.
  void stripeLoaded

  let plan: "individual" | "team" | "enterprise" = "individual"
  let numberOfPeople = 16

  $: submitting = showTOC && !tocAgreed

  async function handleSubmit(this: HTMLFormElement, ev: Event) {
    ev.preventDefault()
    submitting = true
    const createSubscriptionHTTPResponse = await fetch(`${apiURL}/api/Stripe-CreateSubscription`, {
      method: "post",
      body: JSON.stringify({
        subscriptionPlan: plan,
        numberOfPeople: plan === "enterprise" ? numberOfPeople : 1,
        successPath: "/",
      }),
      credentials: "include",
      referrerPolicy: "no-referrer-when-downgrade",
    })
    const createSubscriptionResponse: CreateSubscriptionResponse = await createSubscriptionHTTPResponse.json()
    if (createSubscriptionResponse.error) {
      console.error(createSubscriptionResponse.error)
    } else {
      const stripe = await stripeLoader
      await stripe.redirectToCheckout(createSubscriptionResponse)
    }
  }
</script>

<head>
  <meta name="referrer" content="no-referrer-when-downgrade" />
  <script src="https://js.stripe.com/v3/" on:load={stripeLoaded}>
  </script>
</head>

<Form on:submit={handleSubmit}>
  <FormGroup>
    <FormGroup legendText="Choose payment plan">
      <RadioButtonGroup bind:selected={plan} name="subscription-plan" id="payment-plan">
        <RadioButton value="individual" labelText="Individual plan (1-2)" />
        <RadioButton value="team" labelText="Team plan (3-15)" />
        <RadioButton value="enterprise" labelText="Enterprise (15+)" />
      </RadioButtonGroup>
    </FormGroup>
    {#if plan === "enterprise"}
      <FormGroup>
        <NumberInput label="Number of users" bind:value={numberOfPeople} />
      </FormGroup>
      <p>
        Please contact us before setting up an enterprise account, <a href="mailto:info@kisservices.dev"
          >info@kisservices.dev</a
        >
      </p>
      <br />
    {/if}
    <FormGroup>
      <Button type="submit" bind:disabled={submitting}>{submitCaption}</Button>
      {#if showTOC}
        <div style="margin-top: 20px;">
          <input id="toc" type="checkbox" bind:checked={tocAgreed} />
          <label for="toc" style="display:inline-block"
            >I agree to the <a href="https://docs.google.com/document/d/1kBlUYnIyNonUwjle82gl2MKUh2ZxO9iyTFOczpLX0LU/edit" target="_blank">Terms and Conditions</a></label
          >
        </div>
      {/if}
    </FormGroup>
  </FormGroup>
</Form>
