<script lang="ts">
  import type { APIResponse } from "@kiss/functions/Zoom-ZoomDashboardGetDetails"
  import type { UpdateZoomUserConfigAPIRequest } from "@kiss/functions/Zoom-ZoomDashboardUpdateAccount"

  import type { ZoomAccount } from "@kiss/functions/lib/db/zoom"
  import {
    Form,
    Dropdown,
    TextInput,
    Button,
    Row,
    Grid,
    Column,
    FormGroup,
    ContentSwitcher,
    Switch,
    Checkbox,
  } from "carbon-components-svelte"
  import { utcHourForTime, getTimezoneName, closestTimeIndexForTimezone } from "./utils/dateFaffing"

  export let user: APIResponse
  const apiURL: string = process.env.KISS_SERVICES_FUNCS_URL
  const hasContact = !!user.account.contact
  const hasSpectulativeContact = !!user.account.speculativeContact
  const userTimezone = getTimezoneName()
  console.log(user)

  const contact: APIResponse["account"]["speculativeContact"] = hasContact
    ? user.account.contact
    : hasSpectulativeContact
    ? user.account.speculativeContact
    : {}

  const uploadOptions: Array<{ id: ZoomAccount["filesToBackup"]; text: string }> = [
    { id: "gallery", text: "Gallery View (Recommended)" },
    // { id: 'speaker', text: 'Speaker View (Recommended)' },
    { id: "all videos", text: "All Video Files" },
    { id: "all media", text: "All Video and Audio Files" },
    { id: "all files", text: "All Files (media + transcripts)" },
  ]
  let selectedOption =
    ("filesToBackup" in user?.account?.zoomAccount
      ? uploadOptions.findIndex(o => o.id === user.account.zoomAccount.filesToBackup)
      : 0) || 0

  const transactionalOptions: Array<{ id: ZoomAccount["transactionalEmailNotifications"]; text: string }> = [
    { id: "no updates", text: "Never" },
    { id: "after upload", text: "After Upload" },
    { id: "daily", text: "Daily" },
    { id: "weekly", text: "Weekly" },
  ]

  const emailLabelToIDs = {
    "9:00": "early",
    "12:00": "noon",
    "17:00": "evening",
  }

  let deleteAfterUpload = !!user?.account?.zoomAccount?.deleteAfterUpload

  const emailTimeLabels = Object.keys(emailLabelToIDs)

  const timezoneOffest = new Date().getTimezoneOffset()
  let selectedTransactionalEmailsIndex =
    ("transactionalEmailNotifications" in user?.account?.zoomAccount
      ? transactionalOptions.findIndex(o => o.id === user.account.zoomAccount.transactionalEmailNotifications)
      : 0) || 0

  let selectedEmailTimeIndex: number = closestTimeIndexForTimezone(
    user?.account?.zoomAccount?.transactionalEmailUTCHour,
    timezoneOffest
  )

  let selectedDayIndex: number = 1

  const onSubmit = async (a: Event) => {
    const form = a.target as HTMLFormElement
    const data = new FormData(form)

    const selectorText = document.querySelector("button#filesToBackup > span").textContent
    const option = uploadOptions.find(f => f.text == selectorText)

    const transactionalText = document.querySelector("button#transactionalEmailNotifications > span").textContent
    const transactionalEmailNotifications = transactionalOptions.find(f => f.text == transactionalText).id

    const emailTimeText = document.querySelector("#email-times button[aria-selected=true]").textContent

    const transactionalEmailUTCHour = utcHourForTime(emailLabelToIDs[emailTimeText], timezoneOffest)
    const deleteAfterUpload = (document.getElementById("delete-from-zoom") as HTMLInputElement)?.checked

    const request: UpdateZoomUserConfigAPIRequest = {
      account: {
        contact: {
          firstName: data.get("firstname") as string,
          lastName: data.get("lastname") as string,
          email: data.get("email") as string,
        },
        confirmed: true,
      },
      zoom: {
        filesToBackup: option.id,
        transactionalEmailUTCHour,
        transactionalEmailNotifications,
        deleteAfterUpload,
      },
    }

    try {
      await fetch(`${apiURL}/api/Zoom-ZoomDashboardUpdateAccount`, {
        credentials: "include",
        method: "post",
        body: JSON.stringify(request),
      })

      document.location.reload()
    } catch (error) {
      console.error(error)
    }
  }
</script>

<div id="config">
  <Form on:submit={onSubmit}>
    <Grid>
      <Row>
        <Column>
          <FormGroup>
            <TextInput
              labelText="Email"
              placeholder="Enter user name..."
              type="email"
              name="email"
              value={contact.email}
            />
          </FormGroup>
        </Column>
      </Row>

      <Row>
        <Column aspectRatio="2x1">
          <TextInput labelText="First Name" placeholder="" name="firstname" value={contact.firstName} />
        </Column>
        <Column aspectRatio="2x1">
          <TextInput labelText="Last Name" placeholder="" name="lastname" value={contact.lastName} />
        </Column>
      </Row>

      <Row>
        <Column aspectRatio="2x1">
          <Dropdown
            titleText="Files to backup from Zoom"
            selectedIndex={selectedOption}
            items={uploadOptions}
            id="filesToBackup"
            name="filesToBackup"
          />
        </Column>
        {#if user?.account?.id.length < 4}
          <Column aspectRatio="2x1">
            <div class="labelish">
              <Checkbox
                id="delete-from-zoom"
                labelText="Delete files from Zoom after successful upload"
                checked={deleteAfterUpload}
                name="deleteAfterUpload"
              />
            </div>
          </Column>
        {/if}
      </Row>

      <div style="margin-top: 28px;">
        <Row>
          <Column aspectRatio="2x1">
            <Dropdown
              titleText="When would you prefer upload reports?"
              bind:selectedIndex={selectedTransactionalEmailsIndex}
              items={transactionalOptions}
              id="transactionalEmailNotifications"
              name="transactionalEmailNotifications"
            />
          </Column>
          <Column aspectRatio="2x1">
            <FormGroup legendText="When should we send emails? Using {userTimezone}">
              <span id="email-times">
                <ContentSwitcher bind:selectedIndex={selectedEmailTimeIndex}>
                  <Switch text={emailTimeLabels[0]} disabled={selectedTransactionalEmailsIndex < 2} />
                  <Switch text={emailTimeLabels[1]} disabled={selectedTransactionalEmailsIndex < 2} />
                  <Switch text={emailTimeLabels[2]} disabled={selectedTransactionalEmailsIndex < 2} />
                </ContentSwitcher>
              </span>
            </FormGroup>

            {#if selectedTransactionalEmailsIndex === 3}
              <FormGroup legendText="On what day?">
                <span id="email-times">
                  <ContentSwitcher bind:selectedIndex={selectedDayIndex}>
                    <Switch text="Mon" />
                    <Switch text="Wed" />
                    <Switch text="Fri" />
                  </ContentSwitcher>
                </span>
              </FormGroup>
            {/if}
          </Column>
        </Row>
      </div>

      <Row>
        <Column>
          <FormGroup>
            <center style="margin-top: 24px; text-align: right;">
              <Button type="submit">Submit</Button>
            </center>
          </FormGroup>
        </Column>
      </Row>
    </Grid>
  </Form>
</div>

<style>
  div#config {
    margin: auto;
    margin-top: 60px;
  }
  .labelish {
    margin-top: 35px;
  }
</style>
