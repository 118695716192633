<script lang="ts">
  import Admin from "./Admin.svelte"
  import Account from "./Account.svelte"

  const path = document.location.pathname
  const showAccount = path.startsWith("/admin/accounts/")
  let accountID = ""
  if (showAccount) {
    accountID = document.location.pathname.split("/")[3]
  }
</script>

{#if showAccount }
  <Account accountID={accountID} />
{:else }
  <Admin />
{/if}
