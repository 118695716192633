<script lang="ts">
  import {onMount} from "svelte"
  import type { AdminAPIResponseAccountsType } from "@kiss/functions/Zoom-ZoomDashboardAdminGetDetails/index"
  import { StructuredList, StructuredListHead, StructuredListBody, StructuredListCell, StructuredListRow, Pagination } from "carbon-components-svelte"
  
  const apiURL = process.env.KISS_SERVICES_FUNCS_URL
  export let totalAccounts:number

  let accounts:AdminAPIResponseAccountsType

  let page: number
  let pageSize: number = 10

  const headers = ["Kiss ID", "Confirmed", "Zoom ID", "Email"]

  async function onPaginationChange() {
    try {
      const offset = (page - 1 || 0) * pageSize
      const res = await fetch(`${apiURL}/api/Zoom-ZoomDashboardAdminGetDetails?type=accounts&limit=${pageSize}&offset=${offset}`, { credentials: "include" })
      accounts = await res.json()
    } catch (err) {
      console.log(err)
    }
  }
  onMount(onPaginationChange)

  function listClick(e: MouseEvent) {
    const cell = e.target as HTMLElement
    const parent = cell.parentElement
    document.location.replace("/admin/accounts/" + parent.getAttribute("itemid"))
  }
</script>

<Pagination totalItems={totalAccounts} bind:pageSize={pageSize} on:update={onPaginationChange} bind:page={page} />

{#if accounts}
<StructuredList selection selected="row-1-value" >
  <StructuredListHead>
    <StructuredListRow head>
      {#each headers as header}
        <StructuredListCell head>{header}</StructuredListCell>
      {/each}
    </StructuredListRow>
  </StructuredListHead>
  <StructuredListBody>
    {#each accounts.accounts as row }
    <StructuredListRow itemid={row.id} on:click={listClick}>
      <StructuredListCell>{row.id}</StructuredListCell>
      <StructuredListCell>{row.confirmed ? "✓" : "⨯"}</StructuredListCell>
      <StructuredListCell>{row.accountID || "No Zoom"}</StructuredListCell>
      <StructuredListCell>{row.email || "No contact"}</StructuredListCell>
    </StructuredListRow>
    {/each}
  </StructuredListBody>
</StructuredList>
{/if}
