<script lang="ts">
  import { Tile, Row, Column, CodeSnippet, Button } from "carbon-components-svelte"
  import { onMount } from "svelte"
  import type { AdminAPIResponseAccountType } from "@kiss/functions/Zoom-ZoomDashboardAdminGetDetails/index"
  import Backups from "./Backups.svelte"
  import { hasActiveSubscription, hasActiveTrial } from "@kiss/functions/lib/db/utils";

  export let accountID: string
  let account: AdminAPIResponseAccountType

  async function getAccountDetails() {
    try {
      const apiURL = process.env.KISS_SERVICES_FUNCS_URL
      const res = await fetch(`${apiURL}/api/Zoom-ZoomDashboardAdminGetDetails?type=account&id=${accountID}`, {
        credentials: "include",
      })
      account = await res.json()
    } catch (err) {
      console.log(err)
    }
  }
  onMount(getAccountDetails)

  function gotoAdmin() {
    document.location.replace("/admin")
  }

  async function deleteUser() {
    const confirmed = confirm("Are you sure you want to delete?")
    if (!confirmed) return

    const apiURL = process.env.KISS_SERVICES_FUNCS_URL
    await fetch(`${apiURL}/api/Zoom-ZoomDashboardAdminAPI?type=delete_account&id=${accountID}`, {
      credentials: "include",
    })

    document.location.reload()
  }

  const isStaging = document.location.href.includes("staging") || document.location.href.includes("localhost")
  const stripeURL = (id: string) => `https://dashboard.stripe.com${isStaging ? "/test" : ""}/customers/${id}`
  const dateOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" } as const
  const displayName = account?.account?.speculativeContact?.firstName
    ? `${account.account?.speculativeContact?.firstName} ${account.account?.speculativeContact?.lastName}`
    : `Account ${accountID}`
</script>

{#if account}
  <Button style="float: left; margin-right: 20px;" kind="secondary" size="field" on:click={gotoAdmin}>Back</Button>
  <h1>{displayName}</h1>
  <br />
  <Row style="margin-top: 20px;">
    <Column>
      <Tile>
        <p>Created: {new Date(account.account.createdAt).toLocaleDateString(undefined, dateOptions)}</p>
        <p>Confirmed: {account.account.confirmedAt ? "✓" : "❌"}</p>
        <p>Trial: {hasActiveTrial(account.account) ? "✓" : "❌"}</p>
        <p>Subscription: {hasActiveSubscription(account.account) ? "✓" : "❌"}</p>
        <p>Connected: {account.account.zoomAccount?.deployments.map(d => d.type).join(", ") || "None"}</p>
        <p>Accounts on Zoom: {account.zoomMeta?.userCount || "N/A"}</p>
      </Tile>
    </Column>
    <Column>
      <Tile>
        <h3>Contact</h3>
        <p>
          {account.account?.contact?.firstName || account.account?.speculativeContact?.firstName || "Unknown"}
          {account.account?.contact?.lastName || account.account?.speculativeContact?.lastName || "Unknown"}
        </p>
        <p>{account.account?.contact?.email || account.account?.speculativeContact?.email || "No Email"}</p>
      </Tile>
    </Column>
    <Column>
      <Tile>
        <h3>Subscription</h3>
        <p>
          Stripe Customer ID: <a href={stripeURL(account.account.stripeCustomerID || "")}
            >{account.account.stripeCustomerID}</a
          >
        </p>
        <p>Status: {account.account.subscription?.status || "Not connected"}</p>
      </Tile>
    </Column>
  </Row>

  <Row style="margin-top: 20px;">
    <Column>
      <h2>Raw (sanitized) Account Info</h2>
      <CodeSnippet type="multi" code={JSON.stringify(account.account, null, "  ")} />
    </Column>
  </Row>

  {#if account.account?.zoomAccount?.accountID}
    <Row style="margin-top: 20px;">
      <Column>
        <h2>Uploads</h2>
        <Backups accountID={account.account?.zoomAccount?.accountID} totalLogs={100} />
      </Column>
    </Row>
  {/if}

  <Row style="margin-top: 20px;">
    <Column>
      <Button size="small" kind="danger" on:click={deleteUser}>Delete</Button>
    </Column>
  </Row>
  <br />
{/if}
