<script lang="ts">
  import { onMount } from "svelte"
  import type { PossibleDashboardAPIResponses } from "@kiss/functions/Zoom-ZoomDashboardGetDetails/index"
  import { shouldDoSetup } from "../setup/utils/shouldDoSetup"
  import Dash from "./Dash.svelte"
  import Login from "./Login.svelte"
  import Settings from "./Settings.svelte"
  import Trial from "./Trial.svelte"
  import Migrator from "./Migrator.svelte"

  const apiURL: string = process.env.KISS_SERVICES_FUNCS_URL

  const path = document.location.pathname
  const showSettings = path.startsWith("/settings")
  const showTrial = path.startsWith("/trial")
  const showMigrator = path.startsWith("/migrator")

  let dashInfo: PossibleDashboardAPIResponses | null | undefined = undefined
  onMount(async () => {
    try {
      const res = await fetch(`${apiURL}/api/Zoom-ZoomDashboardGetDetails`, { credentials: "include" })
      dashInfo = (await res.json()) as PossibleDashboardAPIResponses

      // Support someone accidentally ending up in the main UI instead of the setup
      if (dashInfo && "account" in dashInfo && shouldDoSetup(dashInfo.account)) {
        document.location.replace("/setup")
      }
    } catch (err) {
      dashInfo = null
    }
  })
</script>

{#if dashInfo === undefined}
  <div />
{:else if !dashInfo || !("account" in dashInfo)}
  <Login />
{:else if "account" in dashInfo && showTrial}
  <Trial info={dashInfo} />
{:else if "account" in dashInfo && showSettings}
  <Settings info={dashInfo} />
{:else if "account" in dashInfo && showMigrator}
  <Migrator />
{:else if "account" in dashInfo}
  <Dash info={dashInfo} />
{/if}
