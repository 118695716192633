<script lang="ts">
  import {
    Header,
    SideNav,
    SideNavItems,
    Content,
    SideNavLink,
    HeaderUtilities,
    HeaderAction,
    HeaderPanelLinks,
    SkipToContent,
    HeaderPanelLink,
  } from "carbon-components-svelte"

  const path = document.location.pathname
  const showWizard = path.startsWith("/setup")
  const showAdmin = path.startsWith("/admin")
  const apiURL = process.env.KISS_SERVICES_FUNCS_URL

  import Setup from "./setup/Setup.svelte"
  import Dashboard from "./dashboard/Index.svelte"
  import Admin from "./admin/Index.svelte"

  let isSideNavOpen = false
  let rightNavOpen = false
</script>

<Header company="KISS" platformName="Capture Dashboard" bind:isSideNavOpen>
  <div slot="skip-to-content">
    <SkipToContent />
  </div>
  <HeaderUtilities>
    <HeaderAction bind:isOpen={rightNavOpen}>
      <HeaderPanelLinks>
        <HeaderPanelLink href="mailto:info@kisservices.dev">Contact us</HeaderPanelLink>
        <HeaderPanelLink href={apiURL + "/api/Accounts-Logout"}>Log out</HeaderPanelLink>
      </HeaderPanelLinks>
    </HeaderAction>
  </HeaderUtilities>
</Header>

<SideNav bind:isOpen={isSideNavOpen}>
  <SideNavItems>
    <SideNavLink href="/" text="Transfers" />
    <SideNavLink href="/migrator" text="Gdrive Migrator" />
    <SideNavLink href="/settings" text="Settings" />
  </SideNavItems>
</SideNav>

<Content>
  {#if showWizard}
    <Setup />
  {:else if showAdmin}
    <Admin />
  {:else}
    <Dashboard />
  {/if}
</Content>
