<script lang="ts">
  import { ProgressIndicator, Tile, Row, ProgressStep, Column } from "carbon-components-svelte"
  import Zoom from "./Zoom.svelte"
  import GDrive from "./GDrive.svelte"
  import Config from "./Config.svelte"
  import Confirm from "./Confirm.svelte"

  import type { PossibleDashboardAPIResponses } from "@kiss/functions/Zoom-ZoomDashboardGetDetails/index"

  import { onMount } from "svelte"

  let zoomConnected: boolean = false
  let oauthConnected: boolean = false
  let userSetup: boolean = false
  let wizardIndex: -1 | 0 | 1 | 2 | 3 = -1
  let isSkippingRedirect: boolean = false

  const apiURL: string = process.env.KISS_SERVICES_FUNCS_URL
  let userSetupDeets: PossibleDashboardAPIResponses = undefined

  onMount(async () => {
    try {
      const res = await fetch(`${apiURL}/api/Zoom-ZoomDashboardGetDetails`, { credentials: "include" })
      userSetupDeets = (await res.json()) as PossibleDashboardAPIResponses
      wizardIndex = 0
      if (!("account" in userSetupDeets)) return

      const account = userSetupDeets.account
      const isAdmin = userSetupDeets.account?.zoomAccount?.accountID === "bS8LJFWpS7W4o3hhu5wfwQ" && (apiURL.includes("staging") || apiURL.includes("localhost"))
      const isConfirmed = "confirmedAt" in account && !!account.confirmedAt
      isSkippingRedirect = !isAdmin && isConfirmed
      
      // // Have you completed it already?
      if (isConfirmed && !isAdmin) {
        document.location.replace("/")
      }

      // // Figure out what step they are on
      zoomConnected = "zoomAccount" in account
      oauthConnected = zoomConnected && account.zoomAccount && !!account.zoomAccount.deployments.length
      userSetup = "contact" in account

      if (userSetup) {
        wizardIndex = 3
      } else if (oauthConnected) {
        wizardIndex = 2
      } else if (zoomConnected) {
        wizardIndex = 1
      }
    } catch (e) {
      wizardIndex = 0
      console.error("Error getting dashboard details", e.message)
    }
    console.log({ wizardIndex, zoomConnected, oauthConnected, userSetup, userSetupDeets})
  })

</script>

<style>
  h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  p {
    font-size: 0.8rem;
    color: #525252;
    margin-bottom: 24px;
    max-width: 400px;
  }
  article {
    max-width: 700px;
    width: 100%;
    margin: auto;
    height: 400px;
    margin-top: 80px;
  }
  @media (max-width: 640px) {
    article {
      margin-top: 0;
    }
  }
  canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>

<canvas id="confetti"></canvas>

<Row>
  <article>
    <Row>
      <Column>
        <h2>New Account Setup</h2>
        {#if wizardIndex === 0}
        <p>
          Give permissions for KISS Zoom Backup to access your Zoom account, so we can know when a video has finished
          recording.
        </p>
        {:else if wizardIndex === 1}
        <p>Give KISS Backup access to your other accounts, so that we can upload the videos from Zoom.</p>
        {/if}
      </Column>
    </Row>
    <Tile>
      <Row>
        <Column>
          <div style="margin:auto; width:100%;">
            {#if userSetupDeets && 'account' in userSetupDeets}
            <ProgressIndicator spaceEqually currentIndex={wizardIndex}>
              <ProgressStep label="Zoom" complete={zoomConnected} />
              <ProgressStep label="Storage" complete={oauthConnected} />
              <ProgressStep label="Configure" complete={userSetup} />
              <ProgressStep label="Start Trial" />
            </ProgressIndicator>
            {:else}
            <ProgressIndicator spaceEqually currentIndex={wizardIndex}>
              <ProgressStep label="Zoom" />
              <ProgressStep label="Storage" disabled />
              <ProgressStep label="Configure"  disabled/>
              <ProgressStep label="Start Trial" disabled />
            </ProgressIndicator>
            {/if}
          </div>
        </Column>
      </Row>
      <Row>
        {#if wizardIndex === -1}
        <div style="height: 400px;" />
        {:else if wizardIndex === 0}
        <Zoom />
        {:else if wizardIndex === 1}
        <GDrive />
        {:else if wizardIndex === 2 && 'account' in userSetupDeets}
        <Config user={userSetupDeets} />
        {:else if wizardIndex === 3}
        <Confirm/>
        {/if}
      </Row>
    </Tile>
    {#if isSkippingRedirect }
    <p>Skipping redirect because you're an admin</p>
    {/if}
  </article>
</Row>
