<script lang="ts">
  import type { APIResponse } from "@kiss/functions/Zoom-ZoomDashboardGetDetails/index"
  import StripeCreateSubscription from "./StripeCreateSubscription.svelte"
  export let user: APIResponse
  const apiURL = process.env.KISS_SERVICES_FUNCS_URL
</script>

{#if user.account.subscription?.status === 'active' || user.account.subscription?.status === 'trialing'}
  <form method="POST" action={apiURL + '/api/Stripe-ManageBilling'} referrer-policy="no-referrer-when-downgrade">
    <button type="submit">Manage billing</button>
  </form>
  <br /><br />
{:else}
  <StripeCreateSubscription submitCaption="Subscribe" />
{/if}
