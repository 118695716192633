 <script lang="ts">
   import {onMount} from "svelte"
  import { StructuredList, StructuredListHead, StructuredListBody, StructuredListCell, StructuredListRow, Pagination } from "carbon-components-svelte"
  import type { ZoomVideo } from "@kiss/functions/lib/db/zoom";
  
  import { timeSince } from "../utils/timeSince"

  const headers = ["Date", "State", "AccountID"]

  const makeRow = (videos: ZoomVideo[]) => videos.map(vid => {
    if ("uploadState" in vid) {
      return {
        date: timeSince(vid.createdAt) + " ago",
        state: "Uploaded",
        host: vid.zoomAccountID,
        id: vid.id
      }
    } else {
      return {
        date: timeSince(vid.createdAt) + " ago",
        state: "Not Uploaded",
        host: vid.zoomAccountID,
        id: vid.id
      }
    }
  })

  const uploadVideo = (videoID: string) => async (event: Event) => {
    const button = event.target as HTMLButtonElement
    button.disabled = true
    button.value = "Submitting"
    
    const apiURL = process.env.KISS_SERVICES_FUNCS_URL
    const res = await fetch(`${apiURL}/api/Zoom-ZoomManualUpload`, {
      credentials: "include",
      method: "post",
      body: JSON.stringify({ id: videoID }),
    })
    const apiResponse = await res.json()
    console.log({apiResponse})
    button.value = "Started"
  }
  
  const apiURL = process.env.KISS_SERVICES_FUNCS_URL
  export let totalVideos:number
  
  let videos: { videos:  any[] }

  let page: number
  let pageSize: number = 10

  async function onPaginationChange() {
    try {
      const offset = (page - 1 || 0) * pageSize
      const res = await fetch(`${apiURL}/api/Zoom-ZoomDashboardAdminGetDetails?type=videos&limit=${pageSize}&offset=${offset}`, { credentials: "include" })
      videos = await res.json()
    } catch (err) {
      console.log(err)
    }
  }
  onMount(onPaginationChange)
</script>

<Pagination totalItems={totalVideos} bind:pageSize={pageSize} on:update={onPaginationChange} bind:page={page} />

{#if videos}
<StructuredList>
  <StructuredListHead>
    <StructuredListRow head>
      {#each headers as header}
        <StructuredListCell head>{header}</StructuredListCell>
      {/each}
    </StructuredListRow>
  </StructuredListHead>
  <StructuredListBody>
    {#each makeRow(videos.videos || []) as row}
    <StructuredListRow>
      <StructuredListCell>{row.date}</StructuredListCell>
      {#if row.state === "Not Uploaded"}
      <StructuredListCell><button on:click={uploadVideo(row.id)}>Upload</button></StructuredListCell>
      {:else}
      <StructuredListCell><button on:click={uploadVideo(row.id)}>Re-Upload</button></StructuredListCell>
      {/if}
      <StructuredListCell>{row.host}</StructuredListCell>
    </StructuredListRow>
    {/each}
  </StructuredListBody>
</StructuredList>
{/if}
