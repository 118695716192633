<script lang="ts">
  import { onMount } from "svelte"
  import ConfettiGenerator from "confetti-js";
  import { Row, Grid, Column } from "carbon-components-svelte"

  onMount(async () => {
    var confettiSettings = { target: 'confetti' };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  })
</script>

<style>
  div {
    margin: auto;
    margin-top: 60px;
  }
</style>

<div id="bg">

  <Grid>
    <Row>
      <Column>
        <p>That's it! The next time a meeting is Cloud Recorded on Zoom, it'll automatically be uploaded to a new folder. Yes!</p>
        <br/>
        <p>We hope you find it helpful!<br/>Eloy, Maxim & Orta</p>
        <br/><br/>
      </Column>
    </Row>
  </Grid>
</div>
