// https://stackoverflow.com/questions/9772955/how-can-i-get-the-timezone-name-in-javascript\
export function getTimezoneName() {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: "long" });
    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
        const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
        // by this time `trimmed` should be the timezone's name with some punctuation -
        // trim it from both sides
        return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
    }
    else {
        // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
        return full;
    }
}
export function utcHourForTime(str, timezoneOffsetMins) {
    // UTC (the 'timezone' for the funcs) is roughly GMT, the DOM APIs use timezoneOffset on a
    // date which is the minute different between UTC and the users computer
    // In NYC, that value is 240 - which surprised me TBH, as UTC is 4 hours but I thought it should
    // be a negative? ( see: https://time.is/UTC ) What'evs, it is what it is.
    // Sorry people on 15m / 30m offsets
    const hours = Math.round(timezoneOffsetMins / 60);
    let hour = 9;
    if (str === "noon") {
        hour = 12;
    }
    else if (str === "evening") {
        hour = 17;
    }
    // Add the offset
    hour += hours;
    // Wrap around for big timezones
    if (hour > 23) {
        hour = hour - 24;
    }
    return hour;
}
// Has a "bug": if you change timezone it'll reset to 1,
// could support getting closest timezone
export function closestTimeIndexForTimezone(existingTime, currentTimezoneOffsetMins) {
    if (existingTime === undefined)
        return 1;
    const diffMorning = utcHourForTime("early", currentTimezoneOffsetMins);
    if (diffMorning === existingTime)
        return 0;
    const diffNoon = utcHourForTime("noon", currentTimezoneOffsetMins);
    if (diffNoon === existingTime)
        return 1;
    const diffEvening = utcHourForTime("evening", currentTimezoneOffsetMins);
    if (diffEvening === existingTime)
        return 2;
    return 1;
}
