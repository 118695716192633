<script lang="ts">
  import { onMount } from "svelte"
  import {
    StructuredList,
    StructuredListHead,
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    Pagination,
  } from "carbon-components-svelte"
  import type { ZoomLog } from "@kiss/functions/lib/db/zoom"
  import type { AdminAPIResponseLogsType } from "@kiss/functions/Zoom-ZoomDashboardAdminGetDetails/index"

  import { timeSince } from "../utils/timeSince"

  const headers = ["Date", "ID", "State", "Host", "Links"]
  const makeRow = (dashInfo: ZoomLog[]) =>
    dashInfo.map(h => {
      if (h.type === "success") {
        return {
          date: timeSince(h.uploadState.uploadedAt) + " ago",
          id: h.id,
          state: "Uploaded",
          host: h.host,
          files: h.files,
        }
      } else {
        return {
          date: timeSince(h.uploadState.uploadedAt) + " ago",
          state: "Failed",
          host: h.host,
        }
      }
    })

  const apiURL = process.env.KISS_SERVICES_FUNCS_URL
  export let totalLogs: number
  export let accountID: string | undefined

  let logs: AdminAPIResponseLogsType
  let page: number
  let pageSize: number = 10

  async function onPaginationChange() {
    try {
      const offset = (page - 1 || 0) * pageSize
      const subquery = accountID ? `&accountID=${accountID}&` : ""
      const res = await fetch(
        `${apiURL}/api/Zoom-ZoomDashboardAdminGetDetails?type=logs${subquery}&limit=${pageSize}&offset=${offset}`,
        { credentials: "include" }
      )
      logs = await res.json()
    } catch (err) {
      console.log(err)
    }
  }
  onMount(onPaginationChange)
</script>

<Pagination totalItems={totalLogs} bind:pageSize on:update={onPaginationChange} bind:page />

{#if logs}
  <StructuredList>
    <StructuredListHead>
      <StructuredListRow head>
        {#each headers as header}
          <StructuredListCell head>{header}</StructuredListCell>
        {/each}
      </StructuredListRow>
    </StructuredListHead>
    <StructuredListBody>
      {#each makeRow(logs.logs || []) as row}
        <StructuredListRow>
          <StructuredListCell>{row.date}</StructuredListCell>
          <StructuredListCell>{row.state}</StructuredListCell>
          <StructuredListCell>{row.host}</StructuredListCell>
          {#if row.files}
            <StructuredListCell>
              {#each row.files as file}
                <a href={file.href}>
                  {file.sanitizedFilename}
                </a>
              {/each}
            </StructuredListCell>
          {:else}
            <StructuredListCell>N/A</StructuredListCell>
          {/if}
        </StructuredListRow>
      {/each}
    </StructuredListBody>
  </StructuredList>
{/if}
