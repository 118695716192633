<script lang="ts">
  import type { AdminAPIResponseCountsType } from "@kiss/functions/Zoom-ZoomDashboardAdminGetDetails/index"
  import { Tab, Tabs, TabContent } from "carbon-components-svelte"
  import {onMount} from "svelte"
  import Backups from "./Backups.svelte"
  import Webhooks from "./Webhooks.svelte"
  import Accounts from "./Accounts.svelte"

  const apiURL = process.env.KISS_SERVICES_FUNCS_URL
  
  let counts:AdminAPIResponseCountsType
  onMount(async () => {
    try {
      const res = await fetch(`${apiURL}/api/Zoom-ZoomDashboardAdminGetDetails?type=counts`, { credentials: "include" })
      counts = await res.json()
      console.log({counts})
    } catch (err) {
      console.log(err)
      document.location.replace("/")
    }
  })
</script>

<style>
  p {
    margin-bottom: 32px;
  }
</style>

<h3>Admin</h3>
<p>Dig deeper</p>

{#if counts}

<Tabs>
  <Tab label="Accounts" />
  <Tab label="Backup Logs" />
  <Tab label="Zoom Videos" />
  <div slot="content">
    <TabContent><Accounts totalAccounts={counts.accounts} /></TabContent>
    <TabContent><Backups totalLogs={counts.logs} /></TabContent>
    <TabContent><Webhooks totalVideos={counts.videos} /></TabContent> 
  </div>
</Tabs>

{/if}
